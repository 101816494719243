import VChartsLoader from './lib/vcharts_loader.js';

class LabsTestChart {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.load_chart();
  }

  load_chart() {
    var canvas = $('canvas#testChart');
    if (canvas.length == 0) {
      console.error("canvas#testChart is missing from labs/test_chart page");
      return;
    }

    var ctx = canvas.get(0).getContext("2d");

    $.ajax({
      type: 'GET',
      url: '/labs/test_chart_data',
      dataType: 'json',
      success: (data) => {
        console.log("success test_charts_data", data);
        this.vchartsLoader.pass_through_chart(ctx, data);
      }
    })


  }

}

$(document).on('turbo:load', function() {
  if ($("body.labs.test_chart").length == 0) {
    return;
  }
  console.log("page load in labs_test_chart.js");

  var labs_test_chart = new LabsTestChart();
  labs_test_chart.init();
});
