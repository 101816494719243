import { vpsb_lazy_load_images } from '../vpsb/lazy_loader.js';

class GpuPlansPage {

  init() {
    this.setup_filter_change();

    if ($('#hoster-filter').val().length > 0 || $('#gpu-type-filter').val().length > 0) {
      this.loadPlans();
    }
  }

  setup_filter_change() {
    $('#hoster-filter, #gpu-type-filter').on("input", () => {
      if (this.filterTimerId) {
        clearTimeout(this.filterTimerId);
      }

      this.filterTimerId = setTimeout(() => {
        this.loadPlans();
        this.filterTimerId = undefined;
      }, 400);
    });
  }

  loadPlans() {
    const params = {};
    params['hoster_filter'] = $("#hoster-filter").val();
    params['gpu_type_filter'] = $("#gpu-type-filter").val();

    $.get(`/gpu_plans/table`, params, function(data) {
      $("#plans-table").html(data);
      vpsb_lazy_load_images();
    }).fail(function() {
      console.error("load_plans async call failed");
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.gpu_plans.index").length == 0) {
    return;
  }
  console.log("page load in gpu_plans.js");

  var gpuPlansPage = new GpuPlansPage();
  gpuPlansPage.init();
});
