import { SelectizeMultipleSelect } from '../vpsb/lib/selectize_multiple_select.js';
import { show_layout_modal } from './global.js';
import { vpsb_lazy_load_images } from '../vpsb/lazy_loader.js';

class HosterFilter {
  constructor(formElt) {
    this.formElt = formElt;
    this.reloadResultsFromCheckedBoxes();
  }

  submitForm() {
    console.log("submitForm");
    this.loadResults();
  }

  setupSelectCountries() {
    var selectizeCountries = new SelectizeMultipleSelect('#hoster_filter_countries', 'Type country names', 'countries', 'All', () => { this.submitForm(); });
    selectizeCountries.init_select();
    selectizeCountries.setup_select_changes();

    $('#hoster_filter_countries_operator_and').on('change', () => { this.loadResults() });
  }

  loadResults() {
    $.ajax({
      url: $(this.formElt).prop('action'),
      type: 'POST',
      data : $(this.formElt).serialize(),
      success: (data) => {
        $('#filter-results').html(data);
        vpsb_lazy_load_images();
        $('[data-toggle="popover"]').popover();
      },
      error: (data, textStatus, xhr) => {
        var s = `err: ${JSON.stringify(data)}, code: ${data.status}`
        show_layout_modal(s);
        console.log(s);
      }
    });
  }

  reloadResultsFromCheckedBoxes() {
    if ( ( $('.hoster-features input.feature-checkbox:checked').length>0 ||
           $('.vps-features input.feature-checkbox:checked').length>0 )
          && $('#filter-data').data('any-filter') !== true) {
      this.loadResults();
    }
  }

  saveCheckedBoxesInData(featureClass) {
    var checkedFeatureKeys = [];
    $(`${featureClass} input.feature-checkbox:checked`).each(function() {
      checkedFeatureKeys.push($(this).data('key'));
    });
    $(featureClass).attr('data-checked-features', checkedFeatureKeys.join(','));
  }

  setupCheckboxList(featureClass) {
    $(`${featureClass} .feature-checkbox`).on('change', () => {
      this.saveCheckedBoxesInData(featureClass);
      this.loadResults();
      window.history.pushState(history.state, document.title, '/hosters');
    });
  }

  setupProviderCategoryRadios() {
    $('input[name="hoster_filter[provider_category]"]').on('change', () => {
      this.loadResults();
    });
  }

  setupFeatures() {
    this.setupCheckboxList('.hoster-features');
    this.setupCheckboxList('.vps-features');
  }
}


$(document).on('turbo:load', function() {
  if ($("body.hosters.index").length == 0) {
    return;
  }
  console.log("page load in hoster_filter.js");

  var hosterFilter = new HosterFilter('#hoster-filter-form');
  hosterFilter.setupSelectCountries();
  hosterFilter.setupFeatures();
  hosterFilter.setupProviderCategoryRadios();

  $('[data-toggle="popover"]').popover();
});
